<div class="container-fluids" style="display: flex;justify-content: center;">
    <div class="content-pages">
        <div class="page-box row">
            <div class="col-md-6">
                <span class="plant">Plant Analysis Recommendation Tool</span>
                <div class="text-div">
                    <div class="d-flex">
                        <img src="assets/images/plant-analysis-showcase/precision.png" height="39px">
                        <span class="precision">Precision Recommendations</span>
                    </div>
                    
                    <div class="precision-text">
                        <span >Employ advanced algorithms and machine learning to calculate optimal fertilizer amounts, factoring in crop stage, past yields, weather, and soil fertility for precise applications and maximum yields.</span>
                    </div>
                </div>
                <div class="text-div">
                    <div class="d-flex">
                        <img src="assets/images/plant-analysis-showcase/confidence.png" height="39px" alt="">
                        <span class="precision">Confidence in Application</span>
                    </div>
                    <div class="precision-text">
                        <span>Replace guesswork with data-driven insights for confident fertilizer application decisions. Trust our recommendations for precise nutrient timing and amounts, resulting in healthier plants and higher yields.</span>
                    </div>
                </div>
                <div class="text-div">
                    <div class="d-flex">
                        <img src="assets/images/plant-analysis-showcase/environmental.png" height="39px" alt="">
                        <span class="precision">Environmental Sustainability</span>
                    </div>
                    <div class="precision-text">
                        <span>Promote sustainable farming with precision agriculture techniques, minimizing environmental impact. Match nutrient inputs precisely to crop needs to reduce runoff, soil erosion, and safeguard water quality in nearby ecosystems.</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="login">
                    <div class="text-center"><span class="farmer">Grower Login</span></div>
                    <div class="text-center"><span class="font12">Enter your details as shown on your test result</span></div>
                    <form (ngSubmit)="fetchTestResults()">
                        <div class="mt-2">
                            <div class="form-group">
                                <span class="laboratory mb-1">Laboratory</span>
                                <select class="form-control margin-top custom-dropdown" name="companyName" #companyName="ngModel" [(ngModel)]="model.companyName" required>
                                  <option value="" disabled selected hidden>Select Laboratory</option>
                                  <option *ngFor="let name of companyNames" [value]="name">{{name}}</option>
                                </select>                                                            
                            </div>
                              
                        </div>
                        <div class="mt-2">
                            <div class="form-group">
                                <span class="laboratory mb-1">Full Name</span>
                                <input name="farmerName" #name="ngModel" class="form-control margin-top input-box"
                                    [(ngModel)]="model.farmerName" type="text" placeholder="Full Name" required>
                            </div>
                        </div>
                        <div class="mt-2">
                            <div class="form-group">
                                <span class="laboratory mb-1">Zip Code</span>
                                <input name="zipCode" #zipCode="ngModel" class="form-control margin-top input-box"
                                    [(ngModel)]="model.zipCode" type="text" placeholder="Zip Code" required>
                            </div>
                        </div>
                        <div class="text-center">
                            <button class="continue" type="submit">Continue</button>
                        </div>
                        <div *ngIf="errorMessage" class="alert alert-danger custom-alert">
                            {{ errorMessage }}
                        </div>
                    </form>

                </div>
                <div class="footer m-t-70">
                    <img src="assets/images/plant-analysis-showcase/footer.png" height="35px" alt="">
                </div>
            </div>
        </div>
    </div>
</div>