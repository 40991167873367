<div class="container-fluids" style="display: flex;justify-content: center;">
    <div class="content-pages">
        <div class="row page-boxs">
            <div class="col-12 header align-center">
                <div class="col-md-6 col-xl-6 col-lg-6 col-sm-12">
                    <span class="plant">Plant Analysis Recommendation Tool</span>
                </div>
                <div class="col-md-2 col-xl-2 col-lg-2 col-sm-12">Crop: {{ selectedResult?.cropType }}</div>
                <div class="col-md-2 col-xl-2 col-lg-2 col-sm-12">Stage: {{ selectedResult?.stage }}</div>
                <div class="col-md-2 col-xl-2 col-lg-2 col-sm-12 text-right">
                    <img src="assets/images/plant-analysis-showcase/person.png" alt="">
                    <span class="name">{{ selectedResult?.farmerName.split(' ')[0] }}</span>
                </div>
            </div>

        <div class="card-body">
            <div class="row table-div">
                <div class="col-md-4">
                    <span class="text">Your Test Result</span>
                    <table class="tables">
                        <tbody>
                            <tr class="head-tr">
                                <td class="border-right border-left border-top-right head-border">Nutrient</td>
                                <td class="border-top-left head-border border-right-color">Value</td>
                            </tr>
                            <tr *ngFor="let nutrient of nutrients ; let last = last">
                                <td class="f-w-600" [class.border-bottom-right]="last">{{nutrient.name}}</td>
                                <td [class.border-bottom-left]="last">{{nutrient.inputValue}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-8 recomand">
                    <span class="text">Your Recommendation</span>
                    <table class="tables">
                        <tbody>
                            <tr class="head-tr">
                                <td class="border-right border-left border-top-right head-border">Nutrient</td>
                                <td class="border-right border-top-right-left head-border border-left-color" [ngClass]="{'break-word': isMobile}">
                                    <ng-container *ngIf="isMobile; else desktopView">
                                        <span *ngFor="let part of concentrationParts">{{ part }}<br></span>
                                      </ng-container>
                                      <ng-template #desktopView>
                                        Concentration
                                      </ng-template>
                                    </td>
                                <td class="border-right border-top-right-left head-border border-left-color">Amount To Apply</td>
                                <td class="border-top-left head-border border-right-color">Expected Change</td>
                            </tr>
                            <tr id="recommendationsTable" *ngFor="let nutrient of nutrients; let i = index ; let last = last">
                                <td class="f-w-600" [ngClass]="{'noData': nutrient.name == 'Magnesium' || nutrient.name == 'Sodium' || nutrient.name == 'Aluminum'}" [class.border-bottom-right]="last">{{nutrient.name}}</td>
                                <td class="padding" [class.border-bottom-right-left]="last" *ngIf="nutrient.name != 'Magnesium' && nutrient.name != 'Sodium' && nutrient.name != 'Aluminum'">
                                    <select [(ngModel)]="nutrient.defaultRate" (ngModelChange)="recalculateAmount(i)">
                                        <option *ngFor="let rate of rateArr" [value]="rate">{{rate}}%</option>
                                    </select>
                                </td>
                                <td [class.border-bottom-right-left]="last" *ngIf="nutrient.name != 'Magnesium' && nutrient.name != 'Sodium' && nutrient.name != 'Aluminum'">{{nutrient.amount}} oz</td>
                                <td [class.border-bottom-left]="last" *ngIf="nutrient.name != 'Magnesium' && nutrient.name != 'Sodium' && nutrient.name != 'Aluminum'">{{nutrient.change}} {{nutrient.prefix}}</td>

                                <td class="noData" [class.border-bottom-right-left]="last" *ngIf="nutrient.name == 'Magnesium' || nutrient.name == 'Sodium' || nutrient.name == 'Aluminum'"></td>
                                <td class="noData" [class.border-bottom-right-left]="last" *ngIf="nutrient.name == 'Magnesium' || nutrient.name == 'Sodium' || nutrient.name == 'Aluminum'"></td>
                                <td class="noData" [class.border-bottom-left]="last" *ngIf="nutrient.name == 'Magnesium' || nutrient.name == 'Sodium' || nutrient.name == 'Aluminum'"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        
            

            <div class="button-div">
                <button class="back" (click)="back()">Back</button>
                <button class="download" (click)="download()">Download PDF</button>
            </div>
        </div>
    </div>
</div>