import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Data1Service } from '../data1.service';
import { SharedDataService } from '../shared-data-service.service';

@Component({
  selector: 'app-plantanalysis-showcase',
  templateUrl: './plantanalysis-showcase.component.html',
  styleUrls: ['./plantanalysis-showcase.component.scss']
})
export class PlantanalysisShowcaseComponent implements OnInit, AfterViewInit {

  model: any = { companyName: '', farmerName: '', zipCode: '' };
  companyNames: string[] = [];
  testResults: any[] = [];
  errorMessage: string = '';

  constructor(
    private data1Service: Data1Service, 
    private sharedDataService: SharedDataService, 
    private router: Router, 
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
      
      // console.log('Fetching initial data from localStorage');
      // this.model.farmerName = localStorage.getItem('farmerName') || '';
      // this.model.zipCode = localStorage.getItem('zipCode') || '';
    
    this.data1Service.getCompanyNames().subscribe(data => {
      this.companyNames = data;
    }, error => {
      console.error('Failed to fetch company names', error);
    });
  }

  ngAfterViewInit(): void {
      const selectElement = document.querySelector('.custom-dropdown') as HTMLSelectElement;

      // Ensure selectElement is defined before using it
      if (selectElement) {
        this.setSelectColor(selectElement);

        selectElement.addEventListener('change', () => {
          this.setSelectColor(selectElement);
        });
      }
    
  }

  setSelectColor(selectElement: HTMLSelectElement) {
    if (selectElement.value === '') {
      selectElement.style.color = '#D9D9D9'; // Placeholder color
    } else {
      selectElement.style.color = 'black'; // Default color
    }
  }

  fetchTestResults(): void {
    console.log('Debug: Model values:', this.model);
    if (!this.model.companyName || !this.model.farmerName || !this.model.zipCode) {
      alert('All fields are required.');
      console.log('Debug: Missing fields');
      return;
    }

    this.data1Service.getAllFarmerTestResults(this.model.companyName, this.model.farmerName, this.model.zipCode).subscribe(
      data => {
        console.log('Debug: Received data:', data); // Print the result here
          localStorage.setItem('farmerName', this.model.farmerName);
          localStorage.setItem('zipCode', this.model.zipCode);
          localStorage.setItem('testResults', JSON.stringify(data)); 
        
        if (data.length > 1) {
          this.router.navigate(['/plant-analysis-test']);
        } else if (data.length === 1) {
            localStorage.setItem('selectedTestResult', JSON.stringify(data[0]));
          
          this.router.navigate(['/plant-analysis-test-result']);
        } else {
          this.errorMessage = 'No test results found for the provided information.';
        }
      },
      error => {
        this.errorMessage = 'We can’t seem to find your account. Please check you entered the correct information and try again.';
        console.error('Failed to fetch test results', error);
      }
    );
  }
}
