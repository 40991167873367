import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Data1Service } from '../../data1.service';
import { SharedDataService } from '../../shared-data-service.service';

@Component({
  selector: 'app-plantanalysistest',
  templateUrl: './plantanalysistest.component.html',
  styleUrls: ['./plantanalysistest.component.scss']
})
export class PlantanalysistestComponent implements OnInit {

  farmerName: string = 'Default Name';
  testResults: any[] = [];

  constructor(
    private data1Service: Data1Service, 
    private sharedDataService: SharedDataService, 
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    console.log('ngOnInit - Fetching initial data from localStorage');
      this.farmerName = localStorage.getItem('farmerName') || 'Default Name';
      const results = localStorage.getItem('testResults') || '[]';
      console.log('Debug: testResults', this.testResults);
      if (results) {
        this.testResults = JSON.parse(results);
        console.log('ngOnInit - Loaded testResults:', this.testResults);
      }
    
  }

  goToResult(selectedResult: any) {
    
      localStorage.setItem('selectedTestResult', JSON.stringify(selectedResult)); 
    this.router.navigate(['/plant-analysis-test-result']);
  }

  formatDate(dateString: string): string {
    return this.sharedDataService.formatDate(dateString);
  }

  back() {
    this.router.navigate(['/']);
  }
}
