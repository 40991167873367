import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Data1Service {
  constructor(private apollo: Apollo) { }

  getAllFarmerTestResults(companyName: string, farmerName: string, zipCode: string): Observable<any[]> {
    const GET_FARMER_TEST_RESULTS = gql`
      query GetAllFarmerTestResults($companyName: String!, $farmerName: String!, $zipCode: String!) {
        getAllFarmerTestResults(companyName: $companyName, farmerName: $farmerName, zipCode: $zipCode) {
          id
          farmerName
          zipCode
          cropType
          stage
          testDate
          nitrogenValue
          phosphorusValue
          potassiumValue
          calciumValue
          magnesiumValue
          sulfurValue
          sodiumValue
          zincValue
          ironValue
          manganeseValue
          copperValue
          boronValue
          molybdenumValue
          aluminumValue
        }
      }
    `;
  
    return this.apollo.watchQuery<any>({
      query: GET_FARMER_TEST_RESULTS,
      variables: {
        companyName,
        farmerName,
        zipCode,
      },
    }).valueChanges.pipe(
      map(result => result.data.getAllFarmerTestResults),
      );
  }
  

  getCompanyNames(): Observable<string[]> {
    const GET_COMPANY_NAMES = gql`
      query GetCompanyNames {
        companyNames {
          companyName
        }
      }
    `;

    return this.apollo.watchQuery<any>({
      query: GET_COMPANY_NAMES,
    }).valueChanges.pipe(
      map(result => result.data.companyNames.map((company: { companyName: string }) => company.companyName))
    );
  }
}
