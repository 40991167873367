<div class="container-fluids" style="display: flex; justify-content: center;">
    <div class="content-pages">
      <div class="page-boxs">
        <div class="header d-flex align-center">
          <div class="col-md-9">
            <span class="plant">Plant Analysis Recommendation Tool</span>
          </div>
          <div class="col-md-3 text-right">
            <img src="assets/images/plant-analysis-showcase/person.png" alt="">
            <span class="name">{{ farmerName }}</span>
          </div>
        </div>
  
        <div class="card-body">
          <div class="text">
            <div class="col-md-6 p-r-20">
              <div class="">
                <img src="assets/images/plant-analysis-showcase/precision.png" alt="">
                <span class="precision">Nutrient Optimization</span><br>
                <span class="precision-text">Plant analysis helps determine nutrient deficiencies or excesses, enabling precise fertilizer applications to optimize plant health and yield potential.</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="">
                <img src="assets/images/plant-analysis-showcase/precision.png" alt="">
                <span class="precision">Accurate Diagnosis</span><br>
                <span class="precision-text1">Plant Analysis provides precise insights into the nutrient status of crops, enabling targeted adjustments in fertilization strategies to effectively address deficiencies or excesses.</span>
              </div>
            </div>
          </div>
  
          <div class="col-md-12 text1">
            <div class="">
                <span>Hi {{ farmerName ? farmerName.split(' ')[0] : '' }},</span>
            </div>
            <div class="info">
              <span>We’ve found multiple Plant Analysis tests in our system! To select the test you would like the recommendations for, please click on the date of the test you wish to learn more about.</span>
            </div>
          </div>
  
          <div class="col-md-12 table-div">
            <div class="table-wrapper">
              <table class="tables">
                <tbody>
                  <tr class="head-tr">
                    <td>Date Tested</td>
                    <td>Plant Type</td>
                    <td>Stage Tested</td>
                  </tr>
                  <tr *ngFor="let result of testResults" class="cursor-pointer" (click)="goToResult(result)">
                    <td id="determinator">{{ result.testDate | date }}</td>
                    <td>{{ result.cropType }}</td>
                    <td>{{ result.stage }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="button-div">
            <button class="back" (click)="back()">Back</button>
          </div>
  
        <div class="col-md-12 text1">
          <span>If you have any questions or issues, please reach out to Taylor&#64;TransparencyWise.com for technical support.</span>
        </div>
      </div>
    </div>
</div>
  