import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PDFDocument } from 'pdf-lib';
import { SharedDataService } from '../../shared-data-service.service';


@Component({
  selector: 'app-plantanalysistestresult',
  templateUrl: './plantanalysistestresult.component.html',
  styleUrls: ['./plantanalysistestresult.component.scss']
})
export class PlantanalysistestresultComponent implements OnInit {

  isMobile: boolean = false;
  concentrationParts: string[]=[];
  selectedResult: any;
  rateArr = [0.00, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]
  nutrients: ({ name: string; inputValue: string; id: string; low: string; sufficient: string; high: string; excessive: string; median: number; defaultRate: number; STD: number; category: string; range: string; amount: string; change: number; prefix: string; } | { name: string; inputValue: string; id: string; low: string; sufficient: string; high: string; excessive: any; median: number; defaultRate: string; STD: number; category: string; range: string; amount: string; change: number; prefix: string; } | { name: string; inputValue: string; id: string; low: any; sufficient: any; high: string; excessive: string; median: number; defaultRate: string; STD: any; category: string; range: string; amount: string; change: number; prefix: string; })[]=[];
  results: any;
  farmerName: string = ''; 

  constructor(private router: Router, private sharedDataService: SharedDataService, private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {
      console.log('ngOnInit - Fetching initial data from localStorage');
      let resultData = localStorage.getItem('selectedTestResult');

      if (!resultData) {
        const results = localStorage.getItem('testResults');
        if (results) {
          const allResults = JSON.parse(results);
          if (allResults.length === 1) {
            this.selectedResult = allResults[0];
            localStorage.setItem('selectedTestResult', JSON.stringify(this.selectedResult));
            resultData = JSON.stringify(this.selectedResult);
          }
        }
      }

      if (resultData) {
        this.selectedResult = JSON.parse(resultData);
        this.prepareNutrients();
      } else {
        console.error('No test result data available.');
      }

    this.calculateRecommendations();
    
      this.checkScreenWidth(window.innerWidth);
    
  }

  prepareNutrients() {
    if (this.selectedResult) {
      this.nutrients = [
        { name: "Nitrogen", inputValue:this.selectedResult.nitrogenValue ? `${this.selectedResult.nitrogenValue} %` : 'N/A', id: "nitrogen", low: "<4.00", sufficient: "4.00-4.49", high: "4.50-6.00", excessive: ">6.00", median: 6.00, defaultRate: 5.00, STD: 0.002395833, category: '', range: '', amount: '0.00', change: 0,prefix:"%" },
        { name: "Phosphorus", inputValue:this.selectedResult.phosphorusValue ? `${this.selectedResult.phosphorusValue} %` : 'N/A', id: "phosphorus", low: "<0.25", sufficient: "0.25-0.34", high: "0.35-0.55", excessive: ">0.80", median: 0.55, defaultRate: 10.00, STD: 0.000116959, category: '', range: '', amount: '0.00', change: 0,prefix:"%" },
        { name: "Potassium", inputValue:this.selectedResult.potassiumValue ? `${this.selectedResult.potassiumValue} %` : 'N/A', id: "potassium", low: "<1.70", sufficient: "1.70-1.99", high: "2.00-3.00", excessive: '', median: 3.00, defaultRate: 20.00, STD: 0.000260417, category: '', range: '', amount: '0.00', change: 0,prefix:"%" },
        { name: "Calcium", inputValue:this.selectedResult.calciumValue ? `${this.selectedResult.calciumValue} %` : 'N/A', id: "calcium", low: "<0.35", sufficient: "0.35-0.59", high: "0.60-1.50", excessive: '', median: 1.50, defaultRate: 10.00, STD: 0.000625, category: '', range: '', amount: '0.00', change: 0,prefix:"%" },
        { name: "Magnesium", inputValue:this.selectedResult.magnesiumValue ? `${this.selectedResult.magnesiumValue} %` : 'N/A', id: "magnesium", low: "<0.25", sufficient: "0.25-0.29", high: "0.30-0.70", excessive: null, median: 0.70, defaultRate: '', STD: 0.00, category: '', range: '', amount: '0.00', change: 0,prefix:"%" },
        { name: "Sulfur", inputValue:this.selectedResult.sulfurValue ? `${this.selectedResult.sulfurValue} %` : 'N/A', id: "sulfur", low: "<0.20", sufficient: "0.20-0.24", high: "0.25-0.50", excessive: '', median: 0.50, defaultRate: 15.00, STD: 0.0000588235, category: '', range: '', amount: '0.00', change: 0,prefix:"%" },
        { name: "Sodium", inputValue:this.selectedResult.sodiumValue ? `${this.selectedResult.sodiumValue} %` : 'N/A', id: "sodium", low: "<0.018", sufficient: null, high: "0.018-0.022", excessive: '', median: 0.022, defaultRate: '', STD: null, category: '', range: '', amount: '0.00', change: 0,prefix:"%" },
        { name: "Zinc", inputValue:this.selectedResult.zincValue ? `${this.selectedResult.zincValue} PPM` : 'N/A', id: "zinc", low: "<20ppm", sufficient: "20-24ppm", high: "25-60ppm", excessive: ">75ppm", median: 60, defaultRate: 10.00, STD: 0.32, category: '', range: '', amount: '0.00', change: 0,prefix:"PPM" },
        { name: "Iron", inputValue:this.selectedResult.ironValue ? `${this.selectedResult.ironValue} PPM` : 'N/A', id: "iron", low: "<50ppm", sufficient: "50-54ppm", high: "55-300ppm", excessive: ">500ppm", median: 300, defaultRate: 2.00, STD: 0.166666667, category: '', range: '', amount: '0.00', change: 0,prefix:"PPM" },
        { name: "Manganese", inputValue:this.selectedResult.manganeseValue ? `${this.selectedResult.manganeseValue} PPM` : 'N/A', id: "manganese", low: "<20ppm", sufficient: "20-29ppm", high: "30-100ppm", excessive: ">200ppm", median: 100, defaultRate: 2.00, STD: 0.513333333, category: '', range: '', amount: '0.00', change: 0,prefix:"PPM" },
        { name: "Copper", inputValue:this.selectedResult.copperValue ? `${this.selectedResult.copperValue} PPM` : 'N/A', id: "copper", low: "<4ppm", sufficient: "4-5ppm", high: "6-20ppm", excessive: ">50ppm", median: 20, defaultRate: 1.00, STD: 12.8, category: '', range: '', amount: '0.00', change: 0,prefix:"PPM" },
        { name: "Boron", inputValue:this.selectedResult.boronValue ? `${this.selectedResult.boronValue} PPM` : 'N/A', id: "boron", low: "<20ppm", sufficient: "20-24ppm", high: "25-60ppm", excessive: ">80ppm", median: 60, defaultRate: 10.00, STD: 0.00, category: '', range: '', amount: '0.00', change: 0,prefix:"PPM" },
        { name: "Molybdenum", inputValue:this.selectedResult.molybdenumValue ? `${this.selectedResult.molybdenumValue} PPM` : 'N/A', id: "molybdenum", low: "<0.2ppm", sufficient: "0.2-0.9ppm", high: "1.0-5.0ppm", excessive: '', median: 5.0, defaultRate: 10.00, STD: 0.005, category: '', range: '', amount: '0.00', change: 0,prefix:"PPM" },
        { name: "Aluminum", inputValue:this.selectedResult.aluminumValue ? `${this.selectedResult.aluminumValue} PPM` : 'N/A', id: "Aluminum", low: null, sufficient: null, high: "<200ppm", excessive: ">400ppm", median: 200, defaultRate: '', STD: null, category: '', range: '', amount: '0.00', change: 0,prefix:"" },
      ];
    } else {
      this.nutrients = [];
    }
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    
    const target = event.target as Window;
    this.checkScreenWidth(target.innerWidth);
  }

  checkScreenWidth(width: number) {
    this.isMobile = width <= 760;
    if (this.isMobile) {
      this.splitConcentration();
    }
  }

  splitConcentration() {
    const concentration = "Concentration";
    const firstPart = concentration.substring(0, 5); 
    const secondPart = concentration.substring(5);   
    this.concentrationParts = [firstPart, secondPart];
  }

  calculateRecommendations(): void {
    for (let i = 0; i < this.nutrients.length; i++) {
        
        const inputVal = parseFloat(this.nutrients[i].inputValue.replace(/[^0-9.-]/g, '')) || 0;
        this.nutrients[i].category = this.determineCategory(this.nutrients[i], inputVal);
        this.nutrients[i].range = this.nutrients[i].high || 'N/A';

        let change = 0.00;
        if (this.nutrients[i].median !== null && inputVal < this.nutrients[i].median) {
            change = this.nutrients[i].median - inputVal;
            this.nutrients[i].change = parseFloat(change.toFixed(2));
            const amountValue = this.calculateAmount(this.nutrients[i], change, this.nutrients[i].defaultRate);
            
            this.nutrients[i].amount = amountValue > 0 ? amountValue.toFixed(2) : '0.00'; 
        } else {
            this.nutrients[i].change = 0.00;
            this.nutrients[i].amount = '0.00'; 
        }
    }
}


determineCategory(nutrient: any, inputValue: number): string {
  const parseRange = (range: string) => {
      if (!range) return null;
      if (range.includes("<")) {
          return [-Infinity, parseFloat(range.replace("<", ""))];
      }
      if (range.includes(">")) {
          return [parseFloat(range.replace(">", "")), Infinity];
      }
      const parts = range.split('-').map(part => parseFloat(part.replace(/[ppm%]/g, '')));
      if (parts.length === 1) {
          parts.push(Infinity);
      }
      return parts;
  };

  let lowRange = parseRange(nutrient.low);
  let sufficientRange = parseRange(nutrient.sufficient);
  let highRange = parseRange(nutrient.high);
  let excessiveRange = parseRange(nutrient.excessive);

  if (lowRange && inputValue < lowRange[1]) {
      return "Low";
  } else if (sufficientRange && inputValue >= sufficientRange[0] && inputValue <= sufficientRange[1]) {
      return "Sufficient";
  } else if (highRange && inputValue >= highRange[0] && (excessiveRange ? inputValue < excessiveRange[0] : true)) {
      return "High";
  }

  if (excessiveRange && inputValue >= excessiveRange[0]) {
      return "Excessive";
  }

  return "Unknown";
}

calculateAmount(nutrient: any, change: any, rate: any) {
  if (rate === 0) {
      return 0;
  }
  const std = nutrient.STD || 1;
  let amount = change / (rate * std);
  amount = Math.max(0, amount);
  return isFinite(amount) ? amount : 0;
}

recalculateAmount(index: any) {
  if (index >= 0 && index < this.nutrients.length) {
      const nutrient = this.nutrients[index];
      const inputVal = parseFloat(nutrient.inputValue.replace(/[^0-9.-]/g, '')) || 0;
      const selectedRate = parseFloat(nutrient.defaultRate.toString()) || 0; // r

      if (selectedRate !== 0) {
          const change = nutrient.median - inputVal;
          const amountValue = this.calculateAmount(nutrient, change, selectedRate);
          
          nutrient.amount = amountValue > 0 ? amountValue.toFixed(2) : '0.00';
      } else {
          nutrient.amount = '0.00'; 
      }
  }
}

  back() {
    
      const testResults = localStorage.getItem('testResults');
      const results = testResults ? JSON.parse(testResults) : [];
      console.log('Results length:', results.length);

      if (results.length > 1) {
        this.router.navigate(['/plant-analysis-test']);
      } else {
        this.router.navigate(['']);
        localStorage.removeItem('farmerName');
        localStorage.removeItem('testResults');
        localStorage.removeItem('selectedTestResult');
      }
    
  }

formatDate(dateString: string): string {
  return this.sharedDataService.formatDate(dateString);
}

async download() {
  const pdfUrl = 'assets/images/plant-analysis-showcase/Demo-PDF-Plant-Analysis.pdf'; // Path to your PDF file in the assets folder
  const pdfBlob = await this.http.get(pdfUrl, { responseType: 'blob' }).toPromise();

  if (!pdfBlob) {
    console.error('Failed to fetch the PDF file.');
    return;
  }

  const reader = new FileReader();
  reader.onload = async () => {
    const existingPdfBytes = new Uint8Array(reader.result as ArrayBuffer);
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // const { width, height } = firstPage.getSize();
    // console.log(width);
    // console.log(height);

    const width = 595.28;
    const height = 841.89;

    const doc = new jsPDF({ unit: 'pt', format: [width, height] });

    const existingPdfData = await pdfDoc.save();
    const existingPdfBase64 = btoa(String.fromCharCode(...new Uint8Array(existingPdfData)));
    doc.addImage(`data:application/pdf;base64,${existingPdfBase64}`, 'JPEG', 0, 0, width, height);

    // logo
    const logo = new Image();
    logo.src = 'assets/images/logo/TW Logo (28).png';
    doc.addImage(logo, 'PNG', 20, 20, 175, 60); // Adjust position and size as needed

    let farmerName = 'John Doe';
    let zipCode = '32765';
    farmerName = localStorage.getItem('farmerName') || 'John Doe';
    zipCode = localStorage.getItem('zipCode') || '32765';
      
    const plant = this.selectedResult && this.selectedResult.cropType ? this.selectedResult.cropType : 'Soybean';
    const stage = this.selectedResult && this.selectedResult.stage ? this.selectedResult.stage : 'R3';
    const testDate = this.selectedResult && this.selectedResult.test_date ? this.selectedResult.test_date : '05/01/2024';

    // dynamic positions
    doc.setFontSize(12);

    // headers and dynamic content
    doc.setFont('helvetica', 'bold');
    doc.text('Name:', 20, 110);
    doc.setFont('helvetica', 'normal');
    doc.text(farmerName, 60, 110);

    doc.setFont('helvetica', 'bold');
    doc.text('Plant:', 200, 110);
    doc.setFont('helvetica', 'normal');
    doc.text(plant, 240, 110);

    doc.setFont('helvetica', 'bold');
    doc.text('Test Date:', 400, 110);
    doc.setFont('helvetica', 'normal');
    doc.text(this.sharedDataService.formatDate(testDate), 460, 110);

    // Second row
    doc.setFont('helvetica', 'bold');
    doc.text('Zip Code:', 20, 130);
    doc.setFont('helvetica', 'normal');
    doc.text(zipCode, 80, 130);

    doc.setFont('helvetica', 'bold');
    doc.text('Stage:', 200, 130);
    doc.setFont('helvetica', 'normal');
    doc.text(stage, 240, 130);

    const columnWidth = 80; // width for the column
    const spaceBetweenTables = columnWidth;
    const startXFirstTable = 60; // x start position of the first table
    const startXSecondTable = startXFirstTable + (columnWidth * 3) + spaceBetweenTables; // x start position of the second table

     // Add first table (first three columns)
     const tableData1 = this.nutrients.map(nutrient => {
      const inputVal = parseFloat(nutrient.inputValue.replace(/[^0-9.-]/g, '')) || 0;
      const ranking = this.determineCategory(nutrient, inputVal);
      return [
        { content: nutrient.name, styles: { halign: 'left' as const } },
        { content: nutrient.inputValue ? `${nutrient.inputValue}` : 'N/A', styles: { halign: 'center' as const } },
        { content: ranking, styles: { halign: 'center' as const } }
      ];
    });

    autoTable(doc, {
      head: [
        [{ content: 'Nutrient', colSpan: 1, styles: { halign: 'center', fillColor: '#1E4976', textColor: '#FFFFFF', lineWidth: 0.2, lineColor: '#FFFFFF' } },
        { content: 'Value', colSpan: 1, styles: { halign: 'center', fillColor: '#1E4976', textColor: '#FFFFFF', lineWidth: 0.2, lineColor: '#FFFFFF' } },
        { content: 'Ranking', colSpan: 1, styles: { halign: 'center', fillColor: '#1E4976', textColor: '#FFFFFF', lineWidth: 0.2, lineColor: '#FFFFFF' } }]
      ],
      body: tableData1,
      startY: 170,
      theme: 'grid',
      styles: { cellWidth: columnWidth, fontStyle: 'bold'},
      margin: { left: startXFirstTable } 
    });

    // Add second table (last two columns)
    // const tableData2 = this.nutrients.map(nutrient => {
    //   return [
    //     { content: nutrient.defaultRate ? `${nutrient.defaultRate}%` : 'N/A', styles: { halign: 'center' as const } },
    //     { content: nutrient.amount ? `${nutrient.amount} oz` : '0.00 oz', styles: { halign: 'center' as const } }
    //   ];
    // });

    const tableData2 = this.nutrients.map(nutrient => {
      return [
        { content: ['aluminum', 'magnesium', 'sodium'].includes(nutrient.id.toLowerCase()) ? 'N/A' : nutrient.defaultRate ? `${nutrient.defaultRate}%` : '0%', styles: { halign: 'center' as const } },
        { content: ['aluminum', 'magnesium', 'sodium'].includes(nutrient.id.toLowerCase()) ? 'N/A' : (nutrient.amount ? `${nutrient.amount} oz` : '0.00 oz'), styles: { halign: 'center' as const } }
      ];
    });

    autoTable(doc, {
      head: [
        [{ content: 'Rate', colSpan: 1, styles: { halign: 'center', fillColor: '#1E4976', textColor: '#FFFFFF', lineWidth: 0.2, lineColor: '#FFFFFF' } },
        { content: 'Amount', colSpan: 1, styles: { halign: 'center', fillColor: '#1E4976', textColor: '#FFFFFF', lineWidth: 0.2, lineColor: '#FFFFFF' } }]
      ],
      body: tableData2,
      startY: 170,
      theme: 'grid',
      styles: { cellWidth: columnWidth, fontStyle: 'bold', halign: 'center' },
      margin: { left: startXSecondTable } 
    });


    // Save the PDF
    const farmerFullName = this.selectedResult && this.selectedResult.farmerName ? this.selectedResult.farmerName : 'John Doe';
    const farmerNameParts = farmerFullName.split(' ');
    const farmerLastName = farmerNameParts[farmerNameParts.length - 1];
    const filename = `Plant-Analysis-Report-${farmerLastName}.pdf`;
    doc.save(filename);
  };

  reader.readAsArrayBuffer(pdfBlob);
}

}
